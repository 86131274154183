import lenis from "./lenis";
import scrollTriggers from "./scrollTriggers";
import footerSize from "./footerSize";
import feed from "./feed";
import listeners from "./listeners";

function init() {
  footerSize();
  lenis();
  scrollTriggers();
  feed();
  listeners();
  setTimeout(() => {
    document.body.classList.add("__ready");
  }, 300);
}

document.addEventListener("DOMContentLoaded", init);
