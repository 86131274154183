function listeners() {
  const triggers = document.querySelectorAll("[data-open-video]");

  [...triggers].forEach((trigger) => {
    const wrapper = document.querySelector(`[${trigger.dataset.openVideo}]`);
    const close = wrapper.querySelectorAll("[data-close-video]");
    const wrap = wrapper.querySelector("[data-video-wrap]");

    const video = document.createElement("video");
    video.controls = "";
    video.preload = "auto";
    const sourceMP4 = document.createElement("source");
    if (window.innerWidth > 767) {
      sourceMP4.src = require("url:./desktop.mp4");
    } else {
      sourceMP4.src = require("url:./mobile.mp4");
    }
    video.appendChild(sourceMP4);
    wrap.appendChild(video);
    setVideoSize(wrap, video);
    document.addEventListener("resize", setVideoSize(wrap, video));
    trigger.addEventListener("click", () => {
      wrapper.classList.add("__active");
      lenisInstance.stop();
      video.currentTime = 0;
      video.play();
    });
    [...close].forEach((i) => {
      i.addEventListener("click", () => {
        lenisInstance.start();
        wrapper.classList.remove("__active");
        video.pause();
      });
    });
  });
}

function setVideoSize(wrap, video) {
  const videoWidth = video.videoWidth;
  const videoHeight = video.videoHeight;
  const screenWidth = window.innerWidth - (window.innerWidth > 767 ? 140 : 80);
  const screenHeight =
    window.innerHeight - (window.innerWidth > 767 ? 140 : 80);
  let maxWidth, maxHeight;
  if (videoWidth / videoHeight > screenWidth / screenHeight) {
    maxWidth = screenWidth;
    maxHeight = (videoHeight * screenWidth) / videoWidth;
    wrap;
  } else {
    maxHeight = screenHeight;
    maxWidth = (videoWidth * screenHeight) / videoHeight;
  }
  wrap.style.width = maxWidth + "px";
  wrap.style.height = maxHeight + "px";
}

export default listeners;
