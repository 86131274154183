function initLenis() {
  gsap.ticker.lagSmoothing(0);

  const body = document.body.classList;
  let headerOffset = 0;
  if (
    document.body.querySelector(".b__header_v1") ||
    document.body.querySelector(".b__header_v2")
  ) {
    headerOffset = window.innerHeight;
  }

  if (document.body.scrollTop > headerOffset && !body.contains("show-nav")) {
    body.add("show-nav");
  }

  const lenis = new Lenis();
  const langToggle = document.querySelectorAll("[data-lang-switch]");
  lenis.on("scroll", (e) => {
    ScrollTrigger.update;
    if (e.direction > 0) {
      if (e.targetScroll > headerOffset && e.targetScroll > 150) {
        if (body.contains("show-nav")) {
          body.remove("show-nav");
          [...langToggle].forEach((btn) => {
            if (btn.getAttribute("aria-expanded")) {
              btn.classList.remove("focus");
              btn.setAttribute("aria-expanded", false);
            }
          });
        }
      }
    } else {
      if (!body.contains("show-nav")) {
        body.add("show-nav");
      }
      /* if (e.targetScroll > headerOffset) {
      } else {
        if (body.contains("show-nav")) {
          body.remove("show-nav");
        }
      } */
    }
  });

  function raf(time) {
    lenis.raf(time);
    requestAnimationFrame(raf);
  }
  requestAnimationFrame(raf);

  window.lenisInstance = lenis;
}

export default initLenis;
