function footerSize() {
  const footer = document.querySelector(".footer");
  const main = document.querySelector(".main");
  window.addEventListener("resize", set);
  set();
  function set() {
    if (footer.offsetHeight > window.innerHeight) {
      document.body.classList.add("__nf");
    } else {
      document.body.classList.remove("__nf");
      main.style.marginBottom = `${footer.offsetHeight}px`;
    }
  }
  setTimeout(() => {
    footer.classList.add("__active");
  }, 1200);
  return false;
}

export default footerSize;
