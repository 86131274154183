function scrollTriggers() {
  lenisInstance.on("scroll", (e) => {
    ScrollTrigger.update();
    gsap.to(".bar__left div", {
      y: `${e.progress * 50}%`,
    });
    gsap.to(".bar__right div", {
      y: `${e.progress * 50 * -1}%`,
    });
    gsap.to(".bar__top div", {
      x: `${e.progress * 50 * -1}%`,
    });
    gsap.to(".bar__bottom div", {
      x: `${e.progress * 50}%`,
    });
  });

  gsap.fromTo(
    ".bar__left",
    {
      x: "-100%",
    },
    {
      x: 0,
      duration: 1,
      delay: 0.3,
    }
  );
  gsap.fromTo(
    ".bar__right",
    {
      x: "100%",
    },
    {
      x: 0,
      duration: 1,
      delay: 0.3,
    }
  );
  gsap.fromTo(
    ".bar__top",
    {
      y: "-100%",
    },
    {
      y: 0,
      duration: 1,
      delay: 0.3,
    }
  );
  gsap.fromTo(
    ".bar__bottom",
    {
      y: "100%",
    },
    { duration: 1, y: 0, delay: 0.3 }
  );

  gsap.to(".__trigger", {
    scrollTrigger: {
      trigger: ".__gif_wrapper",
      start: "top 80%",
      end: "bottom top",
      scrub: 1,
    },
    y: window.innerWidth > 1024 ? "-100px" : "-40px",
  });

  gsap.to(".__deco_bottom", {
    scrollTrigger: {
      trigger: ".__gif_wrapper",
      start: "top 85%",
      end: "bottom top",
      scrub: 1,
    },
    y: "-40px",
  });

  gsap.to(".__deco_top", {
    scrollTrigger: {
      trigger: ".__gif_wrapper",
      start: "top 85%",
      end: "bottom top",
      scrub: 1,
    },
    y: "40px",
  });

  gsap.to(".__happy_mask", {
    scrollTrigger: {
      trigger: ".__happy_mask",
      start: "top 20%",
      end: "top 0",
      scrub: 1,
    },
    y: "10px",
  });

  setTimeout(() => {
    const timeline = document.querySelector(".feed");
    const scroller = timeline.querySelector(".__scroller");
    const panels = [...timeline.querySelectorAll(".__item")];
    let tlEnd = panels[0].offsetWidth * panels.length;
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: timeline,
        pin: true,
        start: `top ${(window.innerHeight - timeline.offsetHeight - 56) / 2}px`,
        scrub: 1,
        /* markers: true, */
        end: () => `+=${tlEnd}px`,
        onToggle: (self) => {
          /*  left.scrollTrigger.refresh();
          top.scrollTrigger.refresh();
          right.scrollTrigger.refresh();
          bottom.scrollTrigger.refresh(); */
        },
      },
    });

    const offset = window.innerWidth - scroller.offsetWidth;
    const tlEnd2 =
      panels[0].offsetWidth * panels.length - window.innerWidth + offset + 112;
    tl.to(panels, {
      x: () => -(tlEnd2 < 0 ? tlEnd2 * -1 : tlEnd2) + "px",
      ease: "none",
    });

    tl.to(panels, {
      duration: 0.1,
    });
  }, 500);

  return false;
}

export default scrollTriggers;
